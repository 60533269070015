import React, { useContext, useState } from 'react';
export type AutocompleteLanguage = 'en-GB' | undefined;
export interface AutocompleteContextState {
  language: AutocompleteLanguage;
}
interface AutocompleteContextStateWithHandlers extends AutocompleteContextState {
  setLanguage: (autocompleteLanguage: AutocompleteLanguage) => void;
  clearLanguage: () => void;
}
const defaultAutocompleteContextState: AutocompleteContextStateWithHandlers = {
  language: 'en-GB',
  setLanguage: (newLanguage: AutocompleteLanguage) => {},
  clearLanguage: () => {}
};
const AutocompleteContext = React.createContext(defaultAutocompleteContextState);
export const AutocompleteProvider = ({
  children,
  initialState
}: {
  children: React.ReactNode;
  initialState?: AutocompleteContextState;
}) => {
  const [state, setState] = useState(initialState || defaultAutocompleteContextState);
  const setLanguage = (newLanguage: AutocompleteLanguage) => {
    setState(prevState => ({
      ...prevState,
      language: newLanguage
    }));
  };
  const clearLanguage = () => {
    setState(prevState => ({
      ...prevState,
      language: undefined
    }));
  };
  return <AutocompleteContext.Provider value={{
    ...state,
    setLanguage,
    clearLanguage
  }} data-sentry-element="unknown" data-sentry-component="AutocompleteProvider" data-sentry-source-file="autocompleteContext.tsx">
      {children}
    </AutocompleteContext.Provider>;
};
export const useAutocompleteContext = () => useContext(AutocompleteContext);