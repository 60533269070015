import ContactAddress from '../types/ContactAddress'
import RentPaymentOption from '../types/RentPaymentOption'
import YearOfStudy from '../types/YearOfStudy'
import Gender from '../types/Gender'
import BookingFormRoomDetails from '../types/BookingFormRoomDetails'
import GuarantorType from '../types/GuarantorType'
import GuarantorRelationship from '../types/GuarantorRelationship'

export interface Booking {
  details: {
    tenantDetails: {
      firstName: string
      lastName: string
      university: string
      courseTitle: string
      yearOfStudy: YearOfStudy | ''
      gender: Gender | ''
      countryOfCitizenship: string
      dateOfBirth: {
        day: string
        month: string
        year: string
      }
      emailAddress: string
      phoneNumber: {
        phoneNumber: string
        countryCode: string
      }
      address: ContactAddress
    }
    additionalInfo: string
    rentPaymentOption: RentPaymentOption | ''
    guarantorDetails: {
      guarantorType: GuarantorType | ''
      firstName: string
      lastName: string
      emailAddress: string
      phoneNumber: {
        phoneNumber: string
        countryCode: string
      }
      relationship: GuarantorRelationship | ''
      dateOfBirth: {
        day: string
        month: string
        year: string
      }
      address: ContactAddress
    }
  }
  lastStepCompleted: number
  roomDetails?: BookingFormRoomDetails
}

export const defaultBookingState: Booking = {
  details: {
    tenantDetails: {
      firstName: '',
      lastName: '',
      university: '',
      courseTitle: '',
      yearOfStudy: '',
      gender: '',
      countryOfCitizenship: '',
      dateOfBirth: { day: '', month: '', year: '' },
      emailAddress: '',
      phoneNumber: {
        phoneNumber: '',
        countryCode: 'GB',
      },
      address: {
        line1: '',
        line2: '',
        line3: '',
        townCity: '',
        region: '',
        postcode: '',
        country: '',
      },
    },
    additionalInfo: '',
    rentPaymentOption: '',
    guarantorDetails: {
      guarantorType: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: {
        phoneNumber: '',
        countryCode: 'GB',
      },
      relationship: '',
      dateOfBirth: { day: '', month: '', year: '' },
      address: {
        line1: '',
        line2: '',
        line3: '',
        townCity: '',
        region: '',
        postcode: '',
        country: '',
      },
    },
  },
  lastStepCompleted: 0,
}
