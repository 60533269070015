import { useEffect, useState } from 'react'

const useMedia = (queries, values, defaultValue) => {
  const match = () =>
    Boolean(values[queries.findIndex((q) => matchMedia(q).matches)])

  const [value, setValue] = useState(() => defaultValue)

  useEffect(() => {
    setValue(match)

    const handler = () => setValue(match)

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [])

  return value
}

export default useMedia
