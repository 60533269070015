import BookingFormRoomDetails from '../types/BookingFormRoomDetails'
import BookingFormIqRoomDetails from '../types/BookingFormIqRoomDetails'
import IqGender from '../types/IqGender'
import IqYearOfStudy from '../types/IqYearOfStudy'

export interface IqBooking {
  details: {
    firstName: string
    lastName: string
    emailAddress: string
    phoneNumber: {
      phoneNumber: string
      countryCode: string
    }
    countryOfCitizenship: string
    gender: IqGender | ''
    dateOfBirth: {
      day: string
      month: string
      year: string
    }
    university: string
    yearOfStudy: IqYearOfStudy | ''
    iqRoomDetails?: BookingFormIqRoomDetails
  }
  lastStepCompleted: number
  roomDetails?: BookingFormRoomDetails
}

export const defaultIqBookingState: IqBooking = {
  details: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: {
      phoneNumber: '',
      countryCode: 'GB',
    },
    countryOfCitizenship: '',
    gender: '',
    dateOfBirth: { day: '', month: '', year: '' },
    university: '',
    yearOfStudy: '',
  },
  lastStepCompleted: 0,
}
