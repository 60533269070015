import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import Gbp from './svgs/gbp.svg'
import Eur from './svgs/eur.svg'
import Dollar from './svgs/dollar.svg'

const defaultCountryState = {
  country: 'gb',
}

const CountryContext = React.createContext({})

export let useCountryContext = () => useContext(CountryContext)

const currencyCodeToSymbolMap = {
  GBP: '£',
  EUR: '€',
  AUD: '$',
}

const currencyCodeToIconMap = {
  GBP: Gbp,
  EUR: Eur,
  AUD: Dollar,
}

const countryCodeToNameMapping = {
  au: 'Australia',
  gb: 'UK',
  ie: 'Ireland',
}

const countriesThatArePrefixedWithThe = ['gb']

const searchAutocompleteCountryToCurrencyCodeMap = {
  uk: 'GBP',
  'united kingdom': 'GBP',
  ireland: 'EUR',
  australia: 'AUD',
}

const searchAutocompleteCountryToCountryCodeMap = {
  uk: 'gb',
  'united kingdom': 'gb',
  ireland: 'ie',
  australia: 'au',
}

const searchAutocompleteCountryCodeToNameMapping = {
  au: 'australia',
  gb: 'uk',
  ie: 'ireland',
}

const searchAutocompleteCountryCodeToLanguageMapping = {
  au: 'en-GB',
  gb: 'en-GB',
  ie: 'en-GB',
  afsCountries: 'en-GB',
  allCountries: undefined,
}

const searchAutoCompleteCountryRestrictions = {
  afsCountries: ['uk', 'ie', 'au'],
  gb: ['uk'],
  ie: ['ie'],
  au: ['au'],
  allCountries: undefined,
}

export const countryCodeForPropertyAlerts = 'gb'

const countryCodeToSupportPhoneNumberMapping = {
  gb: { display: '0800 078 9659', withCallingCode: '+448000789659' },
  ie: { display: '0289 150 0969', withCallingCode: '+442891500969' },
  au: { display: '0730 732 426', withCallingCode: '+61730732426' },
}

const countryCodeToOfficeAddressesMapping = {
  gb: [
    {
      addressTitle: 'Manchester Office',
      addressLines: [
        'Jactin House',
        '24 Hood Street',
        'Ancoats',
        'Manchester',
        'M4 6WX',
      ],
    },
    {
      addressTitle: 'London Office',
      addressLines: ['164 Acton High Street', 'Acton', 'London', 'W3 6QZ'],
    },
  ],
  ie: [
    {
      addressTitle: 'Belfast Office',
      addressLines: ['19 Arthur Street', 'Belfast', 'BT1 4GA'],
    },
  ],
  au: [
    {
      addressTitle: 'Brisbane Office',
      addressLines: ['29/97 Creek Street', 'Brisbane', 'QLD 4000'],
    },
  ],
}

export const autocompleteCountryNameForCountryCode = (countryCode) => {
  return searchAutocompleteCountryCodeToNameMapping[countryCode]
}

export const symbolForCurrencyCode = (currencyCode) => {
  return currencyCodeToSymbolMap[currencyCode]
}

export const iconForCurrencyCode = (currencyCode) => {
  return currencyCodeToIconMap[currencyCode]
}

export const currencyCodeForCountry = (country) => {
  return searchAutocompleteCountryToCurrencyCodeMap[country.toLowerCase()]
}

export const countryCodeForCountry = (country) => {
  return searchAutocompleteCountryToCountryCodeMap[country.toLowerCase()]
}

export const getSearchAutocompleteLanguageFor = (countryCode) => {
  return searchAutocompleteCountryCodeToLanguageMapping[countryCode]
}

export const getSearchAutoCompleteCountryRestrictions = (countryCode) =>
  searchAutoCompleteCountryRestrictions[countryCode]

export const getGoogleApiTermsToRemove = (countryCode) => {
  const shouldNotRemoveTerms =
    countryCode === 'afsCountries' || countryCode === 'allCountries'
  const shouldRemoveTwoTerms = countryCode === 'au'

  if (shouldNotRemoveTerms) return 0
  if (shouldRemoveTwoTerms) return 2

  return 1
}

export const linkBaseFromCountryCode = (countryCode) => {
  const country = countryCode || 'gb'
  return country === 'gb' ? '' : `/${country}`
}

export const linkQueryFromCountryCode = (countryCode) => {
  const country = countryCode || 'gb'
  return country === 'gb' ? '' : `?country=${country}`
}

export const isLatitude = (numberOfDegrees, countryCode) => {
  switch (countryCode) {
    case 'gb':
      return numberOfDegrees > 40
    case 'ie':
      return numberOfDegrees > 40
    case 'au':
      return numberOfDegrees < -10
  }
}

export const countryStateFromQuery = ({ country }) => {
  const countryCode = country || 'gb'

  return {
    country: countryCode,
    countryName: countryCodeToNameMapping[countryCode],
    countryNameWithPrefix: countriesThatArePrefixedWithThe.includes(countryCode)
      ? `the ${countryCodeToNameMapping[countryCode]}`
      : countryCodeToNameMapping[countryCode],
    linkBase: linkBaseFromCountryCode(countryCode),
    isInternational: countryCode !== 'gb',
    isIreland: countryCode === 'ie',
    usesUkContent: countryCode === 'gb' || countryCode === 'ie',
    supportPhoneNumber: countryCodeToSupportPhoneNumberMapping[countryCode],
    officeAddresses: countryCodeToOfficeAddressesMapping[countryCode],
  }
}

export const countryStateFromUrl = (path) => {
  let countryCode = 'gb'

  if (path.includes('/au') || path.includes('country=au')) {
    countryCode = 'au'
  }

  if (path.includes('/ie') || path.includes('country=ie')) {
    countryCode = 'ie'
  }

  return countryStateFromQuery({ country: countryCode })
}

export const CountryProvider = ({ children, initialState }) => {
  const [state, setState] = useState(initialState || defaultCountryState)

  const update = (nextState) => {
    setState(nextState)
  }

  return (
    <CountryContext.Provider value={{ ...state, update }}>
      {children}
    </CountryContext.Provider>
  )
}

CountryProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.object,
}

export const withCountryServer = (getServerSideProps) => {
  return async (context) => {
    const countryState = countryStateFromQuery(context.query)

    const getServerSidePropsResult = await getServerSideProps(context)

    if (getServerSidePropsResult.redirect) return getServerSidePropsResult
    if (getServerSidePropsResult.notFound) return getServerSidePropsResult

    return {
      props: { ...getServerSidePropsResult.props, countryState },
    }
  }
}

export const homepageLink = () => {
  const { linkBase, isInternational } = useCountryContext()

  if (isInternational) return linkBase

  return '/'
}

export const contactUsLinkFromCountryCode = (countryCode) => {
  const { linkBase, isInternational } = countryStateFromQuery({
    country: countryCode,
  })

  if (isInternational) return `${linkBase}/contact-us`

  return '/contact-us'
}

export const contactUsLink = () => {
  const { country } = useCountryContext()

  return contactUsLinkFromCountryCode(country)
}

export const genericSearchResultsLink = () => {
  const { country, isInternational } = useCountryContext()

  if (isInternational) return `/search-results?countryCode=${country}`

  return '/search-results'
}

export const studentBlogsLinkFromCountryCode = (countryCode) => {
  if (countryCode === 'au') return '/au/student-blog'
  if (countryCode === 'ie') return '/student-blog?country=ie'

  return '/student-blog'
}

export const studentBlogsLink = () => {
  const { country } = useCountryContext()

  return studentBlogsLinkFromCountryCode(country)
}

export const studentBlogLinkFromCountryCode = (countryCode, blogId) => {
  if (countryCode === 'au') return `/student-blog/${blogId}?country=au`
  if (countryCode === 'ie') return `/student-blog/${blogId}?country=ie`

  return `/student-blog/${blogId}`
}

export const studentBlogLink = (blogId) => {
  const { country } = useCountryContext()

  return studentBlogLinkFromCountryCode(country, blogId)
}

export const studentGuidesLinkFromCountryCode = (countryCode) => {
  if (countryCode === 'au') return '/au/student-guides'
  if (countryCode === 'ie') return '/student-guides?country=ie'

  return '/student-guides'
}

export const studentGuidesLink = () => {
  const { country } = useCountryContext()

  return studentGuidesLinkFromCountryCode(country)
}

export const studentGuideLinkFromCountryCode = (countryCode, guideSlug) => {
  if (countryCode === 'au') return `/au/student-guides/${guideSlug}`
  if (countryCode === 'ie') return `/student-guides/${guideSlug}?country=ie`

  return `/student-guides/${guideSlug}`
}

export const studentGuideLink = (guideSlug) => {
  const { country } = useCountryContext()

  return studentGuideLinkFromCountryCode(country, guideSlug)
}
