import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const apiBaseUrl =
  typeof window === 'undefined'
    ? publicRuntimeConfig.apiBaseUrl
    : publicRuntimeConfig.publicApiBaseUrl

const searchBaseUrl =
  typeof window === 'undefined'
    ? publicRuntimeConfig.searchBaseUrl
    : publicRuntimeConfig.publicSearchBaseUrl

const config = {
  ...publicRuntimeConfig,
  apiBaseUrl,
  searchBaseUrl,
  favourites: false,
  imgApiUrl: 'https://accommodationforstudents.com/cdn-cgi/image/f=auto,q=85,',
}

export default config
