class TabbingFocusEventHandlers {
  constructor(window) {
    this.window = window
    this.keyCodeForTab = 9
    this.countRequiredForTabbingFocusToBeAdded = 2
    this.tabbingFocusClass = 'user-is-tabbing'
    this.tabbingActionEventHandler = this.tabbingActionEventHandler.bind(this)
    this.mouseDownEventHandler = this.mouseDownEventHandler.bind(this)
  }

  tabbingActionEventHandler(event) {
    const tabWasPressed = event.keyCode === this.keyCodeForTab

    if (tabWasPressed) {
      this.window.document.body.classList.add(this.tabbingFocusClass)
      this.window.removeEventListener('keydown', this.tabbingActionEventHandler)
      this.window.addEventListener('mousedown', this.mouseDownEventHandler)
    }
  }

  mouseDownEventHandler() {
    this.window.document.body.classList.remove(this.tabbingFocusClass)
    this.window.removeEventListener('mousedown', this.mouseDownEventHandler)
    this.window.addEventListener('keydown', this.tabbingActionEventHandler)
  }
}

export default TabbingFocusEventHandlers
